<template>
    <div class="CollectTopic">
        <!-- 加载中 -->
        <Spin fix v-if="Collect_Loading">
            <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
            <div>Loading</div>
        </Spin>
        <Head />
        <div class="middle">
            <div class="content">
                <div class="CollectList">
                    <div class="List_item" v-for="(item,index) in CollectList" :key="item.questionIndex">
                        <div class="materials" v-if="item.datumText != ''">
                            <div class="materials_top">
                                <span>材料</span>
                                <Button @click="openMaterials(item.datumText)">材料浮窗</Button>
                            </div>
                            <div class="materials_details" v-html="item.datumText" @click="imageEnlargement"></div>
                        </div>
                        <div class="stem">
                            <span>{{item.questionIndex}}、</span>
                            <Tag color="green" v-if="item.typeName == '判断题'">{{item.moduleName}}-{{item.typeName}}</Tag>
                            <Tag color="magenta" v-if="item.typeName == '单选题'">{{item.moduleName}}-{{item.typeName}}</Tag>
                            <Tag color="blue" v-if="item.typeName == '多选题'">{{item.moduleName}}-{{item.typeName}}</Tag>
                            <span v-html="item.stem" class="question_stem" @click="imageEnlargement"></span>
                        </div>
                        <div class="optionList">
                            <div class="option_item" v-for="(item2,index2) in item.optionList" :key="item2.optId">
                                <div class="option_item_code" :class="{option_item_isAnswer:item2.isAnswer === true}">{{item2.code}}</div>
                                <p>{{item2.con}}</p>
                            </div>
                        </div>
                        <div class="isCollect"><img src="@/assets/isCollect_true.png" alt="" @click="ClickCollect(item,index)"></div>
                        <div class="analysis">
                            <p>【答案解析】 正确答案：{{item.rightAnswerStr}}</p>
                            <div v-html="item.analysis" class="analysis_details" @click="imageEnlargement"></div>
                            <p>【考点模块】 {{item.knowledgeDesc}}</p>
                            <p>该知识点的全站正确率：{{item.knowledgeRightRate}}%</p>
                        </div>
                        <div class="item_line"></div>
                    </div>
                    <div class="Ellipsis" v-if="CollectList.length == 0">
                        <img src="@/assets/statistics_Ellipsis.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <!-- 放大图片弹窗 -->
        <Modal v-model="Imgmodal" fullscreen footer-hide :closable="false">
            <div class="BIGimg" @click="Imgmodal = false"><img :src="BIGimg" alt=""></div>
        </Modal>
        
        <!-- 材料浮窗 -->
        <Modal v-model="MaterialsModal" draggable scrollable footer-hide :closable="false" width='485' title="材料">
            <div class="MaterialsModal" v-html="materialsData" @click="imageEnlargement"></div>
            <div class="Materialsclosable"><Button @click="MaterialsModal = false">关闭弹窗</Button></div>
        </Modal>

        <!-- 右下角弹窗 -->
        <div class="fixed_btn">
            <div class="diss" @click="ClickDiss">
                <img src="@/assets/diss.png" alt="">
            </div>
        </div>
        <!-- 我要吐槽弹窗 -->
        <Dissmodal ref="Click_Diss" :title="Diss_title"/>
    </div>
</template>

<script>
// 页头
import Head from "@/components/head"
// 我要吐槽弹窗
import Dissmodal from "@/components/Dissmodal"
import { GetCollectQuestion, CollectQuestionPost} from "@/api/Review"
export default {
    name: 'CollectTopic',
    props: {},
    components: {
        Head,
        Dissmodal,
    },
    data () {
       return {
            knowledgePointId: "",
            Collect_Loading: false,
            Diss_title: "题目反馈",
            // 题目列表
            CollectList: [],
            // 放大图片
            Imgmodal: false,
            BIGimg: '',
            // 材料浮窗
            MaterialsModal: false,
            materialsData: '',
       }
    },
    created () {
        this.knowledgePointId = this.$route.query.id
        this.GetCollectQuestion()
    },
    mounted () {
    },
    methods: {
        // 题目收藏-获取模块下的题目
        GetCollectQuestion(){
            this.Collect_Loading = true
            GetCollectQuestion(this.knowledgePointId).then((res) =>{
                if(res.ret == 1){
                    // 题目列表
                    this.CollectList = res.data
                }else{
                    console.log(res.msg)
                }
                this.Collect_Loading = false
            })
            .catch(err => {
                this.Collect_Loading = false
            });
        },

        // 放大图片
        imageEnlargement(e){
            if (e.target.nodeName == 'IMG') {//判断点击富文本内容为img图片
                this.BIGimg = e.target.currentSrc
                this.Imgmodal = true
   	        } else {
   	        	console.log("点击内容不为img")
   	        }
        },

        // 打开材料浮窗
        openMaterials(e){
            this.materialsData = e
            this.MaterialsModal = true
        },

        // 取消收藏
        ClickCollect(data,index){
            let CollectData = {
                questionId: data.questionId,
                userQuestionId: data.userQuestionId
            }
            CollectQuestionPost(CollectData).then((res) =>{
                if(res.ret == 1){
                    this.GetCollectQuestion()
                }else{
                    console.log(res.msg)
                }
            })
        },

        // 点击我要吐槽
        ClickDiss(){
            this.$refs.Click_Diss.ClickDissmodal()
        },
    },
}
</script> 

<style lang='less' scoped>
    .CollectTopic{
        min-height: 100vh;
        background-color: #f8f8f8;
        position: relative;
        .middle{
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            min-width: 1200px;
            .content{
                width: 1200px;
                min-height: 100vh;
                text-align: left;
                background: #FFFFFF;
                border-radius: 12px;
                margin-top: 80px;
                padding: 43px 30px 77px;
                .CollectList{
                    .List_item{
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                        .materials{
                            background: #F5F5F5;
                            border: 1px solid #D6D6D6;
                            border-radius: 16px 8px 8px 8px;
                            margin-bottom: 30px;
                            .materials_top{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: 24px 30px 28px;
                                font-size: 20px;
                                font-weight: bold;
                                color: #333333;
                            }
                            .materials_details{
                                padding: 20px;
                                color: #666666;
                                background: #FFFFFF;
                                border-top: 1px solid #D6D6D6;
                                border-radius: 16px 8px 8px 8px;
                                /deep/img{
                                    display: block;
                                    margin: 10px auto 0;
                                    max-width: 1100px;
                                }
                            }
                        }
                        .stem{
                            .question_stem{
                                /deep/img{
                                    display: block;
                                    margin: 10px auto 0;
                                    max-width: 1100px;
                                }
                            }
                        }
                        .optionList{
                            margin-top: 30px;
                            padding: 0 20px;
                            .option_item{
                                display: flex;
                                align-items: center;
                                padding: 10px 0;
                                .option_item_code{
                                    width: 32px;
                                    height: 32px;
                                    border: 1px solid #AAAAAA;
                                    border-radius: 50%;
                                    text-align: center;
                                    line-height: 32px;
                                    margin: 0 10px;
                                }
                                .option_item_isAnswer{
                                    background-color: #2FC732;
                                    color: #FFFFFF;
                                    border: 1px solid #2FC732;
                                }
                            }
                            .option_item:hover{
                                background: #F5F5F5;
                                border-radius: 4px;
                            }
                            .option_item:last-child{
                                margin-bottom: 0;
                            }
                        }
                        .isCollect{
                            text-align: right;
                            margin: -10px 0 20px;
                            img{
                                cursor: pointer;
                            }
                        }
                        .analysis{
                            background: #F5F5F5;
                            border-radius: 8px;
                            padding: 20px;
                            font-size: 14px;
                            .analysis_details{
                                margin-bottom: 30px;
                                /deep/img{
                                    display: block;
                                    margin: 10px auto 0;
                                    max-width: 1100px;
                                }
                            }
                        }
                        .item_line{
                            border: 1px solid #F5F5F5;
                            margin: 20px 0 30px;
                        }
                    }
                    .Ellipsis{
                        text-align: center;
                        margin-top: 150px;
                    }
                }
            }
        }
    }
    // 右下角按钮
    .fixed_btn{
        position: fixed;
        right: 40px;
        bottom: 92px;
        img{
            width: 80px;
            height: 80px;
        }
    }
    // 加载中
    .demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
    .demo-spin-col{
        height: 100px;
        position: relative;
        border: 1px solid #eee;
    }
    // 放大图片弹窗
    .BIGimg{
        width: 100%;
        height: 100%;
        text-align: center;
        img{
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            max-width: 1900px;
            height: auto;
        }
    }
    // 材料弹窗
    .MaterialsModal{
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        padding: 4px;
        margin-bottom: 37px;
        max-height: 800px;
        overflow: hidden;
        overflow-y: auto;
        /deep/img{
            display: block;
            margin: 10px auto 0;
        }
    }
    .MaterialsModal::-webkit-scrollbar {
      width: 5px;
      height: 15px;
    }
    .MaterialsModal::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #c1c1c1;
    }
    .MaterialsModal::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #ededed;
      border-radius: 10px;
    }
    .Materialsclosable{
        text-align: center;
    }
</style>
